.App {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.container {
  flex: 1;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question {
  font-size: 19px;
  font-weight: 900;
}

.title {
  margin-bottom: 2px;
  margin-right: 6px;
}

.datePickerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 2px;
}

.datePicker {
  box-sizing: border-box;
  max-width: 95px;
}

.boxes {
  display: block;
  box-sizing: border-box;
  font-size: 4px;
  line-height: 4px;
  /* margin-bottom: 2px; */
  /* height: 6px; */
  /* margin: 3px; */
  /* line-height: 5px; */
  padding: 0;
  margin: 0;
  margin-bottom: 1px;
}

.box {
  display: inline-block;
  box-sizing: border-box;
  line-height: 8px;
  font-size: 4px;
  height: 4px;
  width: 4px;
  /* line-height: 12px; */
  /* padding: 0; */
  margin-right: 1px;
  border: 1px solid #444;
}

.filled {
  background-color: red;
  border-color: red;
}

.noBox {
  border: none;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button {
  /* background-color: #222;
  color: white; */
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 8px;
}

.key {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.keyItem {
  font-size: 12px;
}

.made-by {
  padding-bottom: 10px;
}

.wipe-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

footer {
  text-align: center;
}

.ad {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.made-by {
  padding-top: 6px;
  border-top: 1px solid #ccc;
}
